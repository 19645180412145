import { connect } from 'react-redux';
import {
    getCheckIn,
    getCheckOut,
    getTotalNumberOfNights
} from '../../../../../store/componentStores/Dates/dates.selectors';
import * as globalModalActions from '../../../../../store/componentStores/GlobalModal/globalModal.actions';
import {
    getAdultsCount,
    getKidsAges,
    getKidsCount,
    getNumberOfGuests
} from '../../../../../store/componentStores/Guests/guests.selectors';
import {
    getIsCartActive,
    getLoyaltyEarnThresholdNights,
    getParkingFeeEndDate,
    getParkingFeeStartDate,
    getPerNightParkingFee1,
    getPerNightParkingFee2,
    getPerNightParkingFee3,
    getPointsMultiplier,
    getshowCaliforniaResortFeeVariation,
    getTaxAndFeesLabels
} from '../../../../../store/componentStores/LodgeConfig/lodgeConfig.selectors';
import * as packagesActionCreators from '../../../../../store/componentStores/Packages/packages.actions';
import {
    getPackagesGrossTotal,
    getPackagesTaxes,
    getSelectedPackages
} from '../../../../../store/componentStores/Packages/packages.selectors';
import * as plansActionCreators from '../../../../../store/componentStores/Plans/plans.actions';
import { getIsCaliforniaResident } from '../../../../../store/componentStores/Plans/plans.selectors';
import * as suiteActionCreators from '../../../../../store/componentStores/Suite/suite.actions';
import {
    getParkingFee,
    getPromotionalSavings,
    getRateType,
    getResortFee,
    getRoomAdjustmentTax,
    getSelectedSuite,
    getSuiteGrossTotal,
    getSuiteHeadline,
    getSuiteTaxes,
    getSustainabilityFee,
    getTaxesAndFeesDetailAvailability
} from '../../../../../store/componentStores/Suite/suite.selectors';
import {
    getDueAtCheckInAmount,
    getDueTodayAmount,
    getGrossSummaryTotal,
    getSummaryTotal,
    getValidOfferCode
} from '../../../../../store/reducers';

import isEmpty from 'lodash-es/isEmpty';
import {
    getUserIsLoggedIn,
    getUserLoyaltyOptIn,
    getUserLoyaltyPointsBalance,
    getUserOffers
} from '../../../../../store/componentStores/Account/account.selectors';
import {
    pushToLastVisitedStep,
    resetCartResortLocation,
    setExpiredCart
} from '../../../../../store/componentStores/Cart/cart.actions';
import { getCartResortLocation, getExpiredCart } from '../../../../../store/componentStores/Cart/cart.selectors';
import {
    getResortLocation,
    getResortLocationUrlParam
} from '../../../../../store/componentStores/CurrentLodge/currentLodge.selectors';
import * as offerActionCreators from '../../../../../store/componentStores/Offer/offer.actions';
import {
    getActiveOffers,
    getEmailActiveOffers,
    getOfferCode
} from '../../../../../store/componentStores/Offer/offer.selectors';
import {
    getCartReturnedPageLinkClicked,
    getIsAPScall,
    getIsCartOpenClose
} from '../../../../../store/componentStores/Plans/plans.selectors';
import { showLoyalty } from '../../../../../store/reducers';
import { getTestValueAB, TESTS_DICTIONARY } from '../../../../../utilities/ABTesting';
import { DATE_FORMATS } from '../../../../../utilities/constants';
import { getDateMonthDayFormat } from '../../../../../utilities/dates';
import { getGuestCountLabel } from '../../../../../utilities/guestsUtils';
import { checkIfIsNiagaraProperty } from '../../../../../utilities/navigationUtils';
import { getTaxesAndFees, getTaxesAndFeesDynamic } from '../../../../../utilities/taxes';
import { GwDatesWrapper } from '../../../../_internal_date_/gwDatesWrapper';
import { SUMMARY_CART_VARIATIONS } from '../utilities/constants';
import { Summary } from './Summary';

const getPlanSummaryTotal = (onPaymentPage, state) => {
  return onPaymentPage ? getSummaryTotal(state) : getGrossSummaryTotal(state);
};

const mapStateToProps = (state, { location, variation }) => {
  const { pathname } = location;

  const onPaymentPage = pathname.includes('/payment');

  const cmpUrls = ['confirmation', /my-reservations\/[\w\d]+/];
  const onCmpPage = cmpUrls.some(keyword => {
    if (keyword instanceof RegExp) {
      return keyword.test(pathname);
    }
    return pathname.includes(keyword);
  });

  const onPlanPage = pathname.includes('/plan');

  const selectedPackages = getSelectedPackages(state.packages);

  const taxAndFeeLabels = getTaxAndFeesLabels(state.lodgeConfig);

  const taxesAndFeesPrices = getTaxesAndFeesDetailAvailability(state.suite);
  // This is added until backend changes are ready
  const taxesAndFees =
    !isEmpty(taxAndFeeLabels) && !isEmpty(taxesAndFeesPrices)
      ? getTaxesAndFeesDynamic(taxAndFeeLabels, taxesAndFeesPrices, getPackagesTaxes(state.packages))
      : getTaxesAndFees(
          getSustainabilityFee(state.suite),
          getPackagesTaxes(state.packages) + getSuiteTaxes(state.suite),
          getResortFee(state.suite),
          getParkingFee(state.suite)
        );

  /* ABCD TEST FOR PARKING FEE */
  const optimizelyAddParkingFee1 = getTestValueAB(TESTS_DICTIONARY.optimizelyAddParkingFee1, false);
  const optimizelyAddParkingFee2 = getTestValueAB(TESTS_DICTIONARY.optimizelyAddParkingFee2, false);
  const optimizelyAddParkingFee3 = getTestValueAB(TESTS_DICTIONARY.optimizelyAddParkingFee3, false);
  const checkInDateIsInTheParkingFeeInterval = GwDatesWrapper.isBetween(
    GwDatesWrapper.format(getCheckIn(state.dates), DATE_FORMATS.default),
    GwDatesWrapper.format(getParkingFeeStartDate(state.lodgeConfig), DATE_FORMATS.default),
    GwDatesWrapper.format(getParkingFeeEndDate(state.lodgeConfig), DATE_FORMATS.default)
  );

  let currentParkingFeeAmount = 0;

  if (optimizelyAddParkingFee1) {
    currentParkingFeeAmount = Number(getPerNightParkingFee1(state.lodgeConfig));
  } else if (optimizelyAddParkingFee2) {
    currentParkingFeeAmount = Number(getPerNightParkingFee2(state.lodgeConfig));
  } else if (optimizelyAddParkingFee3) {
    currentParkingFeeAmount = Number(getPerNightParkingFee3(state.lodgeConfig));
  }

  const isParkingFeeABCDTestActive =
    checkInDateIsInTheParkingFeeInterval &&
    (optimizelyAddParkingFee1 || optimizelyAddParkingFee2 || optimizelyAddParkingFee3);

  const parkingFeeTotal = currentParkingFeeAmount * getTotalNumberOfNights(state.dates);

  /* ABCD TEST FOR PARKING FEE */

  const resortLocation = getResortLocation(state.currentLodge);

  return {
    checkIn:
      variation === SUMMARY_CART_VARIATIONS.CART
        ? GwDatesWrapper.format(getCheckIn(state.dates), DATE_FORMATS.monthDayYear)
        : getDateMonthDayFormat(getCheckIn(state.dates)),
    checkOut:
      variation === SUMMARY_CART_VARIATIONS.CART
        ? GwDatesWrapper.format(getCheckOut(state.dates), DATE_FORMATS.monthDayYear)
        : getDateMonthDayFormat(getCheckOut(state.dates)),
    currentSelectedPackages: selectedPackages,
    dueTodayAmount: getDueTodayAmount(state),
    dueAtCheckinAmount: getDueAtCheckInAmount(state),
    isCaliforniaResident: getIsCaliforniaResident(state.plans),
    guestCountText: getGuestCountLabel(getAdultsCount(state.guests), getKidsCount(state.guests), variation),
    hasAdditionalCharges: taxesAndFees && taxesAndFees.length > 0,
    hasSelectedPackages: selectedPackages && selectedPackages.length > 0,
    isNiagaraProperty: checkIfIsNiagaraProperty(resortLocation),
    rateType: getRateType(state.suite),
    numberOfGuests: getNumberOfGuests(state.guests),
    numberOfNights: getTotalNumberOfNights(state.dates),
    onPaymentPage: onPaymentPage,
    packagesTotal: getPackagesGrossTotal(state.packages),
    promoCode: getValidOfferCode(state),
    promotionalSavings: getPromotionalSavings(state.suite),
    suiteTitle: getSuiteHeadline(state.suite),
    suiteTotal: getSuiteGrossTotal(state.suite),
    summaryTotal: getPlanSummaryTotal(onPaymentPage, state),
    taxesAndFees: taxesAndFees,
    pointsBalance: getUserLoyaltyPointsBalance(state.account),
    loyaltyOptIn: getUserLoyaltyOptIn(state.account),
    showLoyalty: showLoyalty(state),
    userOffers: getUserOffers(state.account),
    activeOffers: getActiveOffers(state.offer),
    emailActiveOffers: getEmailActiveOffers(state.offer),
    isUserAuthenticated: getUserIsLoggedIn(state.account),
    getPointsMultiplier: getPointsMultiplier(state.lodgeConfig),
    roomAdjustmentTax: getRoomAdjustmentTax(state),
    isParkingFeeABCDTestActive: isParkingFeeABCDTestActive,
    showCaliforniaResortFeeVariation: getshowCaliforniaResortFeeVariation(state.lodgeConfig),
    currentParkingFeeAmount: currentParkingFeeAmount,
    parkingFeeTotal: parkingFeeTotal,
    offerCode: getOfferCode(state.offer),
    resortLocation,
    selectedSuite: getSelectedSuite(state.suite),
    resortLocationUrlParam: getResortLocationUrlParam(state.currentLodge),
    onCmpPage,
    onPlanPage,
    isApsCall: getIsAPScall(state.plans),
    isCartOpenClose: getIsCartOpenClose(state.plans),
    cartReturnedPageLinkClicked: getCartReturnedPageLinkClicked(state.plans),
    isCartVariation:
      variation === SUMMARY_CART_VARIATIONS.CART &&
      getIsCartActive(state.lodgeConfig) &&
      getTestValueAB(TESTS_DICTIONARY.optimizelyCartFlow, false),
    cartResortLocation: getCartResortLocation(state.cart),
    isCartExpired: getExpiredCart(state.cart),
    adultsCount: getAdultsCount(state.guests),
    kidsAges: getKidsAges(state.guests),
    arrival: getCheckIn(state.dates),
    departure: getCheckOut(state.dates),
    loyaltyEarnThresholdNights: getLoyaltyEarnThresholdNights(state.lodgeConfig),
    kidsCount: getKidsCount(state.guests)
  };
};

const mapDispatchToProps = {
  ...packagesActionCreators,
  ...offerActionCreators,
  openModal: globalModalActions.openModal,
  clearSelectedSuite: suiteActionCreators.clearSelectedSuite,
  pushToPayment: suiteActionCreators.pushToPayment,
  resetCartResortLocation: resetCartResortLocation,
  pushToLastVisitedStep: pushToLastVisitedStep,
  setExpiredCart: setExpiredCart,
  setIsCaliforniaResident: plansActionCreators.setIsCaliforniaResident,
  setIsCartOpenClose: plansActionCreators.setIsCartOpenClose,
  setCartReturnedPageLinkClicked: plansActionCreators.setCartReturnedPageLinkClicked,
  pushToDiningPackagesPage: suiteActionCreators.pushToDiningPackagesPage,
  pushToPackagesPage: suiteActionCreators.pushToPackagesPage,
  updateRateCodes: suiteActionCreators.updateRateCodes
};

const PlanSummary = connect(mapStateToProps, mapDispatchToProps)(Summary);

export { PlanSummary };
