import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import routes from '../../../../../routes';
import { emitEventsByName, EVENTS_DICTIONARY } from '../../../../../utilities/customEventsHelpers';
import { Box, Button } from '../../../../ui-kit';
import Scrollable from '../../../../ui-kit/Scrollable';
import theme from '../../../../ui-kit/themes/theme';
import { editCopyByReplaceString } from '../../../../utilities/copyFunctions';
import { handleTotal } from '../../../../utilities/utils';
import * as COPY from '../utilities/copy';
import CartHeader from './Content/CartHeader';
import { CartModalButtonsContainer, Overlay, OverlayContainer, ScrollContainer } from './styles';

const propTypes = {
  toggleOpen: PropTypes.func,
  cartHasPackages: PropTypes.bool,
  overlayVisible: PropTypes.bool,
  variation: PropTypes.string
};

const defaultProps = {
  toggleOpen: () => {},
  cartHasPackages: false,
  overlayVisible: false,
  variation: null
};

const CartOverlay = ({
  toggleOpen,
  cartHasPackages,
  overlayVisible,
  isCartVariation,
  children,
  onPlanPage,
  setIsCartOpenClose,
  isCartOpenClose,
  isCartExpired,
  ...otherProps
}) => {
  // Dynamic calculation os scroll height
  const [contentScrollHeight, setContentScrollHeight] = useState(0);
  const [continueButtonText, setContinueButtonText] = useState(COPY.CONTINUE_BOOKING);
  const cartHeader = useRef(null);
  const cartFooter = useRef(null);
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md})`
  });

  const navigateToNextStep = () => {
    emitEventsByName(EVENTS_DICTIONARY.FLOW_TRIGGER_NEXT_STEP);
  };

  const navigateToLastStep = () => {
    emitEventsByName(EVENTS_DICTIONARY.FLOW_TRIGGER_LAST_STEP);
  };

  const getContinueButtonText = stepTextEvent => {
    const stepText = stepTextEvent?.detail?.text ?? null;
    if (stepTextEvent) {
      setContinueButtonText(editCopyByReplaceString(COPY.CONTINUE_TO_STEP, stepText, '<STEP>'));
    }
  };

  useEffect(() => {
    overlayVisible && setIsCartOpenClose(true);
  }, [setIsCartOpenClose, overlayVisible]);

  useEffect(() => {
    return () => {
      if (isCartOpenClose && overlayVisible) {
        setIsCartOpenClose(false);
      }
    };
  }, [setIsCartOpenClose, isCartOpenClose, overlayVisible]);

  useEffect(() => {
    if (cartHeader.current && cartFooter.current) {
      const header = document.querySelector('.header-container__inner');
      const headerSize = header ? header.clientHeight : isMobile ? 58 : 68;

      setContentScrollHeight(
        vhToPixels(100) - cartHeader.current.clientHeight - cartFooter.current.clientHeight - headerSize
      );
    }

    if (cartHeader.current && !cartFooter.current) {
      setContentScrollHeight(vhToPixels(100) - cartHeader.current.clientHeight);
    }
  }, [isMobile, overlayVisible]);

  useEffect(() => {
    window.addEventListener(EVENTS_DICTIONARY.FLOW_NEXT_STEP_TEXT, e => getContinueButtonText(e));

    return () => {
      window.removeEventListener(EVENTS_DICTIONARY.FLOW_NEXT_STEP_TEXT, e => getContinueButtonText(e));
    };
  }, []);

  const {
    userOffers,
    activeOffers,
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    currentSelectedPackages,
    setExcedent,
    isUserAuthenticated,
    summaryTotal,
    selectedSuite,
    hasSelectedPackages,
    pushToLastVisitedStep,
    resortLocationUrlParam
  } = otherProps;

  const subTotal = isUserAuthenticated
    ? handleTotal(
        userOffers,
        activeOffers,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        currentSelectedPackages,
        setExcedent
      ).toFixed(2)
    : summaryTotal?.toFixed(2);

  const hasDiningPackages = currentSelectedPackages?.some(pkg => pkg.packageType?.includes('dining'));

  const continueBooking = () => {
    toggleOpen();
    if (onPlanPage) {
      if (hasSelectedPackages && hasDiningPackages) {
        return navigateToLastStep();
      }
      navigateToNextStep();
    } else {
      if (hasSelectedPackages && hasDiningPackages) {
        window.location.href = resortLocationUrlParam.replace(/\/$/, '') + routes.plan.payment;
        return;
      }
      pushToLastVisitedStep();
    }
  };

  return (
    <>
      {isCartVariation ? (
        <Overlay onClick={toggleOpen} isCartVariation={isCartVariation} overlayVisible={overlayVisible}></Overlay>
      ) : null}

      <OverlayContainer
        isDevelopment={process.env.NODE_ENV === 'development'}
        isCartVariation={isCartVariation}
        isCartEmpty={!cartHasPackages}
        overlayVisible={overlayVisible}>
        {isCartVariation ? (
          <Box ref={cartHeader}>
            <CartHeader subTotal={!selectedSuite || Object.keys(selectedSuite).length === 0 ? 0 : subTotal} />
          </Box>
        ) : null}

        <ScrollContainer isCartVariation={isCartVariation} onPlanPage={onPlanPage} isCartEmpty={!cartHasPackages}>
          <Scrollable
            maxHeight={isCartVariation ? contentScrollHeight : null}
            scrollColor={theme.colors.successGreen}
            showTrack
            trackColor={theme.colors.snowMountainGrey[50]}
            hideScrollWhileScrolling={!isCartVariation}>
            {children}
          </Scrollable>
        </ScrollContainer>
        {(isCartVariation && !onPlanPage && cartHasPackages && !isCartExpired) ||
        (isCartVariation && onPlanPage && cartHasPackages) ? (
          <CartModalButtonsContainer py={7} px={[4]} ref={cartFooter}>
            <Button onClick={continueBooking} fluid variant="book">
              {continueButtonText}
            </Button>
          </CartModalButtonsContainer>
        ) : null}
      </OverlayContainer>
    </>
  );
};

CartOverlay.propTypes = propTypes;
CartOverlay.defaultProps = defaultProps;

export default CartOverlay;

function vhToPixels(vh) {
  return Math.round(window.innerHeight / (100 / vh));
}
