import flowActionTypes from './flow.types';

const initialState = {
  loading: false,
  error: null,
  flowSteps: null,
  flowName: null,
  flowAudience: null,
  flowResortLocation: null
};

const flowStepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case flowActionTypes.FETCH_FLOW_STEPS_REQUEST:
      return { ...state, loading: true, error: null };
    case flowActionTypes.FETCH_FLOW_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        flowSteps: action.payload.data.flow,
        flowName: action.payload.flowName,
        flowAudience: action.payload.flowAudience,
        flowResortLocation: action.payload.resortLocation
      };
    case flowActionTypes.FETCH_FLOW_STEPS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default flowStepsReducer;
