import { TESTS_DICTIONARY, getTestValueAB } from '../../utilities/ABTesting';
import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Availability endpoints
 *
 * @class AvailabilityApiclient
 * @extends {GWRApiClient}
 */
export default class AvailabilityApiclient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_AVAILABILITY_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  /**
   * function to get all the availability data
   *
   * @function
   * @param {numberOfAdults: int, location: string, arrival: date, departure: date, offerCode: string, kidsAges= string} availabilityRequest
   * Example: {numberOfAdults: 2, location: 'DELLWI', arrival: '2019-06-01', departure: '2019-06-03', offerCode: 'FLEXTEST', kidsAges: '0,1,2,3,4'}
   */
  async getAvailability(availabilityRequest) {
    const optimizelyForceOpera = getTestValueAB(TESTS_DICTIONARY.optimizelyForceOpera, false);

    if (optimizelyForceOpera) {
      availabilityRequest.forceOpera = true;
    }

    const requiredFields = ['numberOfAdults', 'location', 'arrival', 'departure'];

    const urlRequest = this.url;
    return this.isLoggedIn
      ? await super.getApiCallAuth(urlRequest, availabilityRequest, requiredFields)
      : await super.getApiCall(urlRequest, availabilityRequest, true, requiredFields);
  }

  /**
   * function to get all the availability content for a single suite
   *
   * @function
   * @param {numberOfAdults: int, location: string, arrival: date, departure: date, offerCode: string, kidsAges= string} suiteRequest
   * Example: {numberOfAdults: 2, location: 'DELLWI', arrival: '2019-06-01', departure: '2019-06-03', offerCode: 'FLEXTEST', kidsAges: '0,1,2,3,4'}
   */
  async getSuiteData(suiteRequest) {
    const optimizelyForceOpera = getTestValueAB(TESTS_DICTIONARY.optimizelyForceOpera, false);

    if (optimizelyForceOpera) {
      suiteRequest.forceOpera = true;
    }
    const { property, roomType } = suiteRequest;

    const urlRequest = `${this.url}/Suites/${property}?suiteCode=${roomType}`;

    delete suiteRequest.property;
    delete suiteRequest.roomType;

    return this.isLoggedIn
      ? await super.getApiCallAuth(urlRequest, suiteRequest)
      : await super.getApiCall(urlRequest, suiteRequest);
  }

  /**
   * function to get the suite charges
   *
   * @function
   * @param {suiteCode: string, property: string, arrival: date, departure: date, rateCode: string, Adults: int, children: Array[int]} suiteDataRequest
   * Example: { suiteCode: 'FSSX', property: 'WILLVA', arrival: '2030-06-01', departure: '2030-06-03', rateCode:'AMTCERT', Adults: 2, children: [1, 3, 5]}
   */
  async getAvailabilitySuiteCharges(suiteDataRequest) {
    const urlRequest = `${this.url}/${suiteDataRequest.suiteCode}/charges`;
    delete suiteDataRequest.suiteCode;

    let request = await super.getApiCall(urlRequest, suiteDataRequest);

    return request;
  }

  /**
   * function to get all the availability data by suite
   *
   * @function
   * @param {string} suite
   * The suite which we are requesting
   * Example: 'WPFB'
   * @param {numberOfAdults: int, location: string, arrival: date, departure: date, offerCode: string, rateCode: string, kidsAges: string} availabilityBySuiteRequest
   * Example: {numberOfAdults: 2, location: 'DELLWI', arrival: '2019-06-01', departure: '2019-06-03', offerCode: 'FLEXTEST', rateCode:'BBAR', kidsAges: '0,1,2,3'}
   */
  async getAvailabilityBySuite(suite, availabilityBySuiteRequest) {
    const urlRequest = `${this.url}/${suite}`;
    return this.isLoggedIn
      ? await super.getApiCallAuth(urlRequest, availabilityBySuiteRequest)
      : await super.getApiCall(urlRequest, availabilityBySuiteRequest);
  }

  /**
   * function to get all the range of days of availability data by suite
   *
   * @function
   * @param {numberOfAdults: int, location: string, arrival: date, departure: date, rateCode: string, packagesCodes: string, kidsAges: string} availabilityPackagesRequest
   * Example: {numberOfAdults: 2, location: 'DELLWI', arrival: '2019-06-01', departure: '2019-06-03', rateCode:'BBAR', packagesCodes: '1130BDAY, 1230BDAY', kidsAges: '0,1,2,3'}
   */
  async getAvailabilityPackage(availabilityPackagesRequest) {
    const urlRequest = `${this.url}/packages`;

    const request = await super.getApiCall(urlRequest, availabilityPackagesRequest);

    return request;
  }

  /**
   * function returns the number of available items per day with price and taxes
   *
   * @function
   * @param {StartDate: date, EndDate: date, ItemGroup: string, Location: string, NumberOfGuests:int} availabilityDayPassRequest
   * Example: {StartDate: 2019-10-01, EndDate: 109-10-02, ItemGroup: CA, Location: GRAPTX, NumberOfGuests : 1}
   */
  async getAvailabilityDayPasses(availabilityDayPassRequest) {
    const urlRequest = `${this.url}/daypass/availability`;

    let request = await super.getApiCall(urlRequest, availabilityDayPassRequest);

    return request;
  }

  /**
   * function returns the number of available items per day with price and taxes
   *
   * @function
   * @param {Arrival: date, Departure: date, RateCode: string, Property: string, Adults:int} availabilityDayPassRequest
   * Example: {StartDate: 2019-10-01, EndDate: 109-10-02, ItemGroup: CA, Location: GRAPTX, NumberOfGuests : 1}
   */
  async getAvailabilityRoomChargesDayPasses(availabilityDayPassRequest) {
    const urlRequest = `${this.url}/WPPASS/charges`;

    let request = await super.getApiCall(urlRequest, availabilityDayPassRequest);

    return request;
  }

  /**
   * function returns offer description
   *
   * @function
   * @param {Object} offerCodeDescriptionRequest Config object.
   * @param {sring} offerCodeDescriptionRequest.location  Lodge location.
   * @param {sring} offerCodeDescriptionRequest.offerCode  Offer Code
   * Example: {location: 'GURNIL', offerCode: 'AMTCERT'}
   */
  async getOfferCodeDescription(offerCodeDescriptionRequest) {
    const urlRequest = `${this.url}/rate-description`;

    let request = await super.getApiCall(urlRequest, offerCodeDescriptionRequest);

    return request;
  }

  /**
   * function that wrapps the call to get a deal from a promo code. Is a call to daypass endpoint too
   *
   * @function
   * @param {StartDate: date, EndDate: date, ItemGroup: string, Location: string, NumberOfGuests:int} availabilityDayPassRequest
   * Example: {StartDate: 2019-10-01, EndDate: 109-10-02, ItemGroup: CA, Location: GRAPTX, NumberOfGuests : 1}
   */
  async getDealByPromoCode(property, promoCode) {
    const urlRequest = `${this.url}/Deals/${property}/${promoCode}`;
    let request = await super.getApiCall(urlRequest);
    return request;
  }

  /**
   * function that wrapps the call to get the suite bundle package
   *
   * @function
   * @param {Property: string, suiteCode: string}
   * Example: {Property: 'POCOPA', suiteCode: 'FSSB'}
   */
  async getSuiteBundlePackage(property, suiteCode) {
    const urlRequest = `${this.url}/${property}/${suiteCode}/upsell-packages`;
    let request = await super.getApiCall(urlRequest);
    return request;
  }
}
