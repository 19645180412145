// Reservation Details Selectors

import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';
import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';
import { EReservationStatus } from '../../../utilities/availabilityUtils';
import {
    CABANAS_CODES_TYPES,
    CHECKOUT_TIMES,
    DATE_FORMATS,
    DAY_PASSES_TYPE,
    HOWL_N_LEARN_CODE,
    LATE_CHECKOUT_PREFIXES
} from '../../../utilities/constants';

/**
 * Get lastName (users) for the reservation.
 * @return {String} - Arrival Date;
 */
export const getLastName = state => get(state, 'data.lastName');

/**
 * Get firstName (users) for the reservation.
 * @return {String} - Reservation guest's first name;
 */
export const getFirstName = state => get(state, 'data.firstName');

/**
 * Get check-in (arrival) date for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {String} - Arrival Date.
 */
export const getArrivalDate = state => get(state, 'data.arrival');

/**
 *
 * @param {Object} state reservationDetails entity store.
 * @returns {String} - Arrival Date as Date Object
 */
export const getArrivalDateObject = state => {
  const date = get(state, 'data.arrival');
  if (!date) return date;

  return GwDatesWrapper.format(date, DATE_FORMATS.default);
};

/**
 *
 * Get check-out (departure) date for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {String} - Departure Date.
 */
export const getDepartureDate = state => get(state, 'data.departure');

/**
 *
 * @param {Object} state reservationDetails entity store.
 * @returns {String} - Departure Date as Date Object
 */
export const getDepartureDateObject = state => {
  const date = get(state, 'data.departure');
  if (!date) return date;

  return GwDatesWrapper.format(date, DATE_FORMATS.default);
};

/**
 * Get the offer code added for the reservation.
 * @param {Object} state Reservations store.
 * @return {string} Confirmed reservation offer code.
 */
export const getOfferCode = state => get(state, 'data.offerCode');

/**
 * Get the id for the reservation.
 * @param {Object} state Reservations store.
 * @return {string} Confirmed reservation id.
 */
export const getReservationId = state => get(state, 'data.id');

/**
 * Get the id for the reservation on APS.
 * @param {Object} state Reservations store.
 * @return {string} Confirmed reservation id.
 */
export const getReservationIdOnAps = state => get(state, 'data.externalId');

/**
 * Get the reservation status.
 * @param {Object} state Reservations store.
 * @return {string} Confirmed reservation status.
 */
export const getStatus = state => get(state, 'data.status');

/**
 * Get the number of adults.
 * @param {Object} state Reservations store.
 * @return {number} Confirmed reservation number of adults.
 */
export const getAdults = state => get(state, 'data.selection.numAdults');

/**
 * Get the number of billable kids.
 * @param {Object} state Reservations store.
 * @return {number} Confirmed reservation number of billable kids.
 */
export const getBillableGuest = state => {
  const numChildrenOverTwo = get(state, 'data.selection.numChildrenOverTwo');
  return getAdults(state) + numChildrenOverTwo;
};

/**
 * Get the number of children.
 * @param {Object} state Reservations store.
 * @return {number} Confirmed reservation number of children.
 */
export const getKids = state => get(state, 'data.selection.numChildren');

/* Get the selected ages of kids for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {array} - kids ages
 */
export const getKidsAges = state => {
  return get(state, 'data.children') || [];
};

/**
 * Get the room type.
 * @param {Object} state Reservations store.
 * @return {string} Confirmed reservation room type.
 */
export const getRoomType = state => get(state, 'data.roomType');

/**
 * Get the property.
 * @param {Object} state Reservations store.
 * @return {string} Confirmed reservation property.
 */
export const getProperty = state => get(state, 'data.property');

/**
 * Get the number of nights for reservation.
 * @param {Object} state Reservations store.
 * @return {number} Confirmed reservation number of nights.
 */
export const getNumberOfNights = state => get(state, 'data.selection.numberOfNights');

/**
 * Get sutie details for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {Object} - Suite details[Suite name, adults count, kids count, infants count, offer code] of the confirmed suite.
 */
export const getSuiteDetails = (state, includePropertyAndRoomType) => {
  const reservation = get(state, 'data');
  let suiteDetail = {};
  if (reservation) {
    // Extracting suite details
    const { selection, rateInfo } = reservation;
    if (reservation.isCabana) {
      suiteDetail.title = reservation.roomDescription;
    }

    // Adding total room rate for selected suite
    if (rateInfo) {
      const { totalRoomRate } = rateInfo;
      suiteDetail.totalRoomRate = totalRoomRate;
    }
    // Setting guests count.
    if (selection) {
      const { numAdults, numChildrenOverTwo, numChildren } = selection;
      suiteDetail.adults = numAdults;
      suiteDetail.kids = numChildrenOverTwo;
      suiteDetail.totalKids = numChildren;
    }
    suiteDetail.offerCode = reservation.offerCode;
    if (includePropertyAndRoomType) {
      suiteDetail.property = reservation.property;
      suiteDetail.roomType = reservation.roomType;
    }
  }
  return suiteDetail;
};

/**
 * Get the total room rate.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Total room rate of confirmed suite for the reservation.
 */
export const getTotalRoomRate = state => {
  return get(state, 'data.rateInfo.totalRoomRate', 0);
};

/**
 * Get the taxes amount.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Taxes amount for the reservation.
 */
export const getTaxesAmount = state => {
  return get(state, 'data.rateInfo.taxes');
};

/**
 * Get the taxes amount including cabanas.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Taxes amount for the reservation including cabanas.
 */
export const getTaxesAmountWithCabanas = state => {
  const taxesAmount = get(state, 'data.rateInfo.taxes');

  const cabanas = get(state, 'data.cabanas');
  let totalTaxesCabanas = 0;
  if (cabanas) {
    totalTaxesCabanas = cabanas
      .filter(c => c.status !== EReservationStatus.CANCELED)
      .map(c => c.totalTaxes)
      .reduce((total, roomRate) => {
        return total + roomRate;
      }, 0);
  }
  return taxesAmount + totalTaxesCabanas;
};

/**
 * Get the resort fee for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Resort fee for the reservation.
 */
export const getResortFee = state => {
  return get(state, 'data.rateInfo.resortFee');
};

/**
 * Get the parking fee for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Parking fee for the reservation.
 */
export const getParkingFee = state => {
  return get(state, 'data.rateInfo.parkingFee');
};

/**
 * Get the Sustainability  fee for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Resort fee for the reservation.
 */
export const getSustainabilityFee = state => {
  return get(state, 'data.rateInfo.sustainabilityFee');
};

/**
 * Get the summary total for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Summary total for the reservation.
 */
export const getSummaryTotal = state => {
  return get(state, 'data.rateInfo.total');
};

/**
 * Gets the summary total for the reservation including cabanas.
 * @param {Object} state reservationDetails entity store.
 * @returns {number} - Summary total for the reservation including cabanas.
 */
export const getSummaryTotalWithCabana = state => {
  const cabanas = get(state, 'data.cabanas');
  let totalCabanas = 0;
  if (cabanas) {
    totalCabanas = cabanas
      .filter(c => c.status !== EReservationStatus.CANCELED)
      .map(c => c.total)
      .reduce((total, totalCabanaWithTaxes) => {
        return total + totalCabanaWithTaxes;
      }, 0);
  }
  return getSummaryTotal(state) + totalCabanas;
};

/**
 * Get total room rate plus packages rate.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Total room rate plus packages.
 */
export const getTotalRoomRateAndPackages = state => {
  return get(state, 'data.rateInfo.totalRoomRateAndPackages', 0);
};

/**
 * Get the package total for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Total amount of the packages selected.
 */
export const getPackageTotal = state => {
  return getTotalRoomRateAndPackages(state) - getTotalRoomRate(state);
};

/**
 * Get the package total with cabanas for the reservation.
 * @param {Object} state reservationDetails entity store
 * @returns {number} Total sum of amounts for packages including cabanas
 */
export const getPackageTotalWithCabanas = state => {
  const cabanas = get(state, 'data.cabanas');
  let totalCabanas = 0;
  if (cabanas) {
    totalCabanas = cabanas
      .filter(c => c.status !== EReservationStatus.CANCELED)
      .map(c => c.totalRoomRate)
      .reduce((total, roomRate) => {
        return total + roomRate;
      }, 0);
  }
  return getPackageTotal(state) + totalCabanas;
};

/**
 * Get the deposit paid for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - deposit paid for the reservation.
 */
export const getDepositePaid = state => {
  const roomDeposit = get(state, 'data.advancedDeposit', get(state, 'data.rateInfo.advancedDeposit', 0));
  const cabanaDeposit = get(state, 'data.cabanasDepositAmount');
  return roomDeposit + cabanaDeposit;
};

/**
 * Get the room deposit paid for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - deposit paid for the reservation.
 */
export const getRoomDeposit = state => {
  const roomDeposit = get(state, 'data.advancedDeposit', get(state, 'data.rateInfo.advancedDeposit', 0));

  return roomDeposit;
};

/**
 * Get the site for reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {string} Confirmed reservation site.
 */
export const getSite = state => get(state, 'data.selection.site');

/**
 * Get packages added to the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {array.<Object>} List of packages added.
 */
export const getPackages = state => get(state, 'data.packages', []) || [];

/**
 * Get cabanas added in the same days of the reservation.
 * @param {Object} state reservationDetails entity store.
 * @returns {array.<Object>} List of cabanas added in the same days of the reservation.
 */
export const getCabanas = state => get(state, 'data.cabanas', []) || [];

/**
 * Get packages with cabanas added to the reservation.
 * @param {Object} state reservationDetails entity store.
 * @returns {array.<Object>} List of packages added including cabanas.
 */
export const getPackagesWithCabanas = state => {
  let packagesReturn = [];

  const packages = getPackages(state);
  const cabanas = getCabanasAsPackages(state);

  if (packages) {
    packagesReturn.push(...packages);
  }
  if (cabanas) {
    packagesReturn.push(...cabanas);
  }

  return packagesReturn;
};

/**
 * Get the display name for confirmed reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {string} Confirmed reservation display name.
 */
export const getDisplayName = state => get(state, 'data.selection.siteDisplayName');

/**
 * Get the value of isLoading.
 * @param {Object} state reservationDetails entity store.
 * @return {boolean} True if we are fetching data.
 */
export const getIsLoading = state => get(state, 'isLoading');

/**
 * Get the value of requestSuccess.
 * @param {Object} state reservationDetails entity store.
 * @return {boolean} True if we succesfully fetched data.
 */
export const getRequestSuccess = state => get(state, 'requestSuccess');

/**
 * Get the error message.
 * @param {Object} state ReservationDetails entity store.
 * @return {string} Error message
 */
export const getError = state => get(state, 'errorMessage');

/**
 * Get the checkout time selected for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {String} - String containing the checkout time.
 */
export const getCheckoutTime = state => {
  const reservation = get(state, 'data');
  let checkoutTime = CHECKOUT_TIMES.AM_11;
  if (reservation) {
    const { packages } = reservation;
    if (packages) {
      const lateCheckoutPkg = packages.find(
        pkg => pkg.packageCode.includes(LATE_CHECKOUT_PREFIXES.code) || pkg.packageCode.includes(HOWL_N_LEARN_CODE)
      );

      if (lateCheckoutPkg) {
        checkoutTime = CHECKOUT_TIMES.PM_2;
      }
    }
  }
  return checkoutTime;
};

/**
 * Get the whole reservation object
 * @param {Object} state
 */
export const getReservation = state => {
  return get(state, 'data');
};

/**
 * Get the due at Checkin amount for the reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {number} - Due at Checkin amount for the reservation.
 */
export const getDueAtCheckinAmount = state => {
  return getSummaryTotal(state) - getDepositePaid(state);
};

/**
 * Get the due at Checking amount for the reservation including cabanas.
 * @param {Object} state reservationDetails entity store.
 * @returns {number} - Due at Checkin amount for the reservation.
 */
export const getDueAtCheckinAmountWithCabanas = state => {
  const totalWithCabana = Number(getSummaryTotalWithCabana(state).toFixed(2));
  const totalDepositPaid = Number(getDepositePaid(state).toFixed(2));
  return totalWithCabana - totalDepositPaid;
};

/**
 * Types of reservations.
 * @enum {string}
 */
export const RESERVATION_TYPES = {
  suite: 'suite',
  dayPass: 'dayPass',
  cabana: 'cabana'
};
/**
 * Returns if is a suite, day pass or a cabana reservation.
 * @param {Object} state Reservations store.
 * @return {String} Type of reservation
 */
export const getReservationType = state => {
  const roomType = getRoomType(state);
  const dayPassesCodes = Object.values(DAY_PASSES_TYPE);
  const cabanaCodes = Object.values(CABANAS_CODES_TYPES);
  if (dayPassesCodes.includes(roomType)) {
    return RESERVATION_TYPES.dayPass;
  } else if (cabanaCodes.includes(roomType)) {
    return RESERVATION_TYPES.cabana;
  }

  return RESERVATION_TYPES.suite;
};

/**
 * Get the Nor1 upgrade details for reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {Object} EStandBy upgrade details.
 */
export const getNor1EstandByDetails = state => get(state, 'data.nor1Response');

/**
 * Get the check-in details for reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {Object} EStandBy upgrade details.
 */
export const getCheckIn = state => get(state, 'data.checkIn');

/**
 * Get the special request from reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {array.<Object>} List of special request added.
 */
export const getSpecialRequests = state => get(state, 'data.specialRequest');

/**
 * Get the special request from reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {Object} Birth Day Celebration Special Request.
 */
export const getBirthDayCelebration = state => get(state, 'data.birthDayCelebration');

/**
 * Get comments from reservation.
 * @param {Object} state reservationDetails entity store.
 * @return {array.<Object>} List of comments.
 */
export const getComments = state => get(state, 'data.comments');

/**
 * Get Reservation's packages
 * @param {Object} state reservationDetails entity store
 * @return {array.<Object>} List of packages.
 */
export const getReservationPackages = state => {
  return get(state, 'data.packages') || [];
};

const getCabanasAsPackages = state => {
  // Check if this is cabana object is present.
  const cabanasEntity = get(state, 'data.cabanas', []);
  if (!cabanasEntity) {
    return;
  }

  const cabanasFiltered = cabanasEntity.filter(c => c.status !== EReservationStatus.CANCELED);
  const cabanas = sortBy(cabanasFiltered, item => GwDatesWrapper.format(item.insertedAt, DATE_FORMATS.numberFormat), [
    'asc'
  ]);
  return cabanas.reduce((cabanasAcc, currentCabana, index) => {
    const cabanaToAdd = {
      description: currentCabana.roomDescription,
      shortDescription: currentCabana.roomDescription,
      endDate: currentCabana.arrival,
      startDate: currentCabana.arrival,
      packageAmount: currentCabana.totalRoomRate,
      packageCode: index === cabanas.length - 1 ? currentCabana.roomType : `${currentCabana.roomType}_${index}`,
      quantity: 1,
      tax: currentCabana.totalTaxes,
      totalAmount: currentCabana.total,
      unitAmount: currentCabana.totalRoomRate,
      isCabana: true,
      totalRoomRateAndPackages: currentCabana.totalRoomRateAndPackages
    };
    cabanasAcc.push(cabanaToAdd);
    return cabanasAcc;
  }, []);
};

export const getIsDayPass = state => get(state, 'data.isDayPass');

export const getIsFullDayPass = state => get(state, 'data.isFullDayPass');

export const getSuppressRate = state => get(state, 'data.rateInfo.suppressRate');

export const getCabanaDeposit = state => {
  const cabanaDepositEntry = get(state, 'data.cabanasDepositAmount');

  return cabanaDepositEntry;
};

export const getPointsDiscountApplied = state => get(state, 'data.rateInfo.totalPoints');

export const getOffersDiscountApplied = state => get(state, 'data.rateInfo.totalOffers');

export const getReservationAddress = state => {
  return state.reservationDetails?.data?.address || {};
};
export const getCanAddGuestsFlag = state => {
  return state?.data?.canAddGuests;
};

/**
 * Get the number of adults and kids.
 * @param {Object} state Reservations store.
 * @return {number} Confirmed reservation number of adults and kids.
 */
export const getTotalBillAndNonBillGuest = state => {
  return getAdults(state) + getKids(state);
};

export const getPackageErrorMessage = state => {
  return state.packageErrorMessageData;
};

export const getAddGuestReservationObj = state => {
  return state.addGuestReservationSuccess;
};

export const getAddGuestReservationErrorMessage = state => {
  return state.addGuestReservationErrorMessage;
};

export const getRateType = state => {
  return state.data.rateType || '';
};
