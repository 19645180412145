import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentResort } from '../../../../store/componentStores/CurrentLodge/currentLodge.selectors';
import * as dayPassActionCreators from '../../../../store/componentStores/DayPasses/dayPasses.actions';
import {
    getArrivalDate,
    getDayPassParkingFee,
    getDayPassResortFee,
    getDayPassSustainabilityFee,
    getDayPassesGrossTotal,
    getDayPassesNetTotal,
    getNumberOfGuests,
    getPromoCode,
    getSelectedDescription,
    getSelectedRate,
    getSelectedTitle,
    getTaxesAndFees
} from '../../../../store/componentStores/DayPasses/dayPasses.selectors';
import { getParkingFeeWarning } from '../../../../store/componentStores/LodgeConfig/lodgeConfig.selectors';
import * as packageActions from '../../../../store/componentStores/Packages/packages.actions';
import { getSelectedPackages } from '../../../../store/componentStores/Packages/packages.selectors';
import { SummaryContent } from '../Content';
import { Divider } from '../Content/styles';
import SummaryAccordion from '../SummaryAccordion';
import { AdditionalCharges } from './Content/AdditionalCharges';
import { PackagesList } from './Content/PackageList';
import DayPassDetail from './DayPassDetail';
import DayPassFooter from './DayPassFooter';
import DayPassHeader from './DayPassHeader';
import { DayPassParkingFeeWarning } from './DayPassParkingFeeWarning';

export const DayPassSummary = ({
  selectedDescription,
  promoCode,
  summaryTotal,
  arrivalDate,
  selectedTitle,
  selectedRate,
  numberOfGuests,
  onChange,
  shouldStartCollapsed,
  selectedPackages,
  deletePackage,
  sustainabilityFee,
  parkingFee,
  resortFee,
  taxesAndFees,
  bookingSummaryParkingFee,
  currentLodge
}) => {
  const handleTotal = () => {
    if (selectedPackages.length < 1) {
      return summaryTotal;
    }
    let newTotal = summaryTotal;
    selectedPackages &&
      selectedPackages.forEach(item => {
        newTotal += item.amount;
      });
    return newTotal;
  };

  const handleTaxes = () => {
    let newTaxesTotal = taxesAndFees;
    if (parkingFee > 0) {
      newTaxesTotal -= parkingFee;
    }
    if (sustainabilityFee > 0) {
      newTaxesTotal -= sustainabilityFee;
    }
    if (resortFee > 0) {
      newTaxesTotal -= resortFee;
    }
    if (selectedPackages.length < 1) {
      return newTaxesTotal;
    }
  };

  return (
    <SummaryAccordion total={handleTotal()} onChange={onChange} shouldStartCollapsed={shouldStartCollapsed}>
      {({ expanded, isDesktop }) => {
        return (
          <>
            {expanded ? (
              <SummaryContent>
                <DayPassHeader validOn={arrivalDate} amountOfDayPasses={numberOfGuests} />
                <DayPassDetail
                  passTitle={selectedTitle}
                  promoCode={promoCode}
                  dayPassPromoDescription={selectedDescription}
                  rate={selectedRate}
                  amountOfDayPasses={numberOfGuests}
                />
                <Divider />
                <PackagesList currentSelectedPackages={selectedPackages} deletePackage={deletePackage} />
                <AdditionalCharges
                  taxes={handleTaxes()}
                  sustainabilityFee={sustainabilityFee}
                  parkingFee={parkingFee}
                  resortFee={resortFee}
                  lodgeOperaCode={currentLodge.operaCode}
                />
              </SummaryContent>
            ) : null}
            {expanded || isDesktop ? <DayPassFooter total={handleTotal()} /> : null}
            {expanded ? <DayPassParkingFeeWarning bookingSummaryParkingFee={bookingSummaryParkingFee} /> : null}
          </>
        );
      }}
    </SummaryAccordion>
  );
};

DayPassSummary.propTypes = {
  dayPassPromoDescription: PropTypes.string,
  promoCode: PropTypes.string,
  summaryTotal: PropTypes.number,
  arrivalDate: PropTypes.string,
  selectedTitle: PropTypes.string,
  selectedRate: PropTypes.number,
  dayPassesTotal: PropTypes.number,
  numberOfGuests: PropTypes.number,
  onChange: PropTypes.func,
  shouldStartCollapsed: PropTypes.bool
};

export default connect(
  state => {
    return {
      selectedRate: getSelectedRate(state.dayPasses),
      selectedTitle: getSelectedTitle(state.dayPasses),
      promoCode: getPromoCode(state.dayPasses),
      numberOfGuests: getNumberOfGuests(state.dayPasses),
      arrivalDate: getArrivalDate(state.dayPasses),
      dayPassesTotal: getDayPassesGrossTotal(state.dayPasses),
      summaryTotal: getDayPassesNetTotal(state.dayPasses),
      selectedDescription: getSelectedDescription(state.dayPasses),
      selectedPackages: getSelectedPackages(state.packages),
      parkingFee: getDayPassParkingFee(state.dayPasses),
      sustainabilityFee: getDayPassSustainabilityFee(state.dayPasses),
      resortFee: getDayPassResortFee(state.dayPasses),
      taxesAndFees: getTaxesAndFees(state.dayPasses),
      currentLodge: getCurrentResort(state.currentLodge),
      bookingSummaryParkingFee: getParkingFeeWarning(state.lodgeConfig)
    };
  },
  dispatch => {
    return {
      ...bindActionCreators(dayPassActionCreators, dispatch),
      ...bindActionCreators(packageActions, dispatch)
    };
  }
)(DayPassSummary);
