// Birthday Party action types

const birthdayPartyActionTypes = {
  FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA: 'FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA',
  FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_SUCCESS: 'FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_SUCCESS',
  FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_ERROR: 'FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_ERROR',
  SET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA: 'SET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA',
  RESET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA: 'RESET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA'
};

export default birthdayPartyActionTypes;
